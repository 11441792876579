<template>
    <div>
        <Loader :isLoading="isLoading" />
        <div class="container my-5" v-if="postingLimitReached">
            <h4>Surplus Posting Limit Reached!</h4>
            <br />
            <router-link :to="{ name: 'pricing' }">Upgrade My Plan</router-link>
        </div>
        <div v-else-if="!this.surplusPosted">
            <div class="commen-hader">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <h2>SUBMIT YOUR SURPLUS</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12">
                            <div class="profile-edit">
                                <h2><i class="fa fa-edit"></i> SUBMIT NEW SURPLUS</h2>

                                <form
                                    class="about-me-profile"
                                    enctype="multipart/form-data"
                                    @submit="register"
                                >
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="title"
                                            v-model="title"
                                            placeholder="Surplus Title "
                                            class="form-control"
                                        />
                                        <FormError :title="formErrors.title" />
                                    </div>
                                    <div class="form-group">
                                        <textarea
                                            placeholder="Description * "
                                            rows="5"
                                            required=""
                                            name="description"
                                            v-model="description"
                                            class="form-control"
                                        ></textarea>
                                        <FormError :title="formErrors.description" />
                                    </div>

                                    <!-- <div class="form-group">
                                        <input
                                            type="text"
                                            name="tag"
                                            v-model="tag"
                                            placeholder="Tags"
                                            class="form-control"
                                        />
                                        <FormError :title="formErrors.tag" />
                                    </div> -->
                                    <h3>IMAGE AND VIDEO</h3>
                                    <div class="upload-img">
                                        <div class="row">
                                            <div class="col-sm-12" style="padding-bottom: 20px;">
                                                <ImageUploader
                                                    :single="true"
                                                    @update="image = $event"
                                                />
                                                <ImageUploader @update="filelist = $event" />
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea
                                                        name="video_code"
                                                        v-model="video_code"
                                                        placeholder="Related Video link (optional)"
                                                        rows="5"
                                                        class="form-control"
                                                    ></textarea>
                                                </div>
                                                <p>
                                                    Add video embedding code here(youtube,vimeo,etc)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>SELECT CATEGORY TYPE</h3>
                                    <div class="row">
                                        <div class="col">
                                            <select
                                                class="form-control"
                                                name="category"
                                                v-model="category"
                                                v-on:change="getsubcategory"
                                            >
                                                <option disabled value="">&#xf0c9; Category</option>
                                                <option
                                                    v-for="categoryItem in categores"
                                                    :key="categoryItem.id"
                                                    :value="categoryItem.id"
                                                    >{{ categoryItem.name }}</option
                                                >
                                            </select>
                                            <FormError :title="formErrors.category" />
                                        </div>

                                        <div class="col">
                                            <select
                                                name="subcategory"
                                                v-if="subcategores.length"
                                                v-model="subcategory"
                                                class="form-control"
                                            >
                                                <option disabled value="">Sub-Category</option>
                                                <option
                                                    v-for="(subCategoryItem, index) in subcategores"
                                                    :key="index"
                                                    :value="subCategoryItem.parent_id"
                                                    >{{ subCategoryItem.name }}</option
                                                >
                                            </select>
                                            <FormError :title="formErrors.subcategory" />
                                        </div>
                                    </div>
                                    <h3>Quantity SETTING</h3>
                                    <div class="form-group">
                                        <input
                                            type="number"
                                            name="quenty"
                                            placeholder="Quantity"
                                            v-model="quenty"
                                            class="form-control"
                                        />
                                        <FormError :title="formErrors.quantity" />
                                    </div>

                                    <h3>PRICE SETTING</h3>
                                    <div class="row">
                                        <div class="col-6">
                                            <input
                                                type="number"
                                                name="price"
                                                placeholder="Price (Rs)"
                                                v-model="price"
                                                class="form-control"
                                            />
                                            <FormError :title="formErrors.price" />
                                        </div>
                                        <!-- <div class="col-6">
                                            <input
                                                type="text"
                                                name="offer"
                                                placeholder="Offer Price"
                                                v-model="offer"
                                                class="form-control"
                                            />
                                        </div> -->
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input
                                                    type="number"
                                                    name="mobile"
                                                    placeholder="Contact Number"
                                                    v-model="mobile"
                                                    id="number"
                                                    class="form-control"
                                                />
                                                <FormError :title="formErrors.mobile" />
                                            </div>
                                        </div>
                                    </div>
                                    <!--<input-->
                                    <!--  type="type"-->
                                    <!--  name="mobile"-->
                                    <!--  placeholder="Phone Number * "-->
                                    <!--  required=""-->
                                    <!--  v-model="user.mobile"-->
                                    <!--  id="number"-->

                                    <!--/>-->

                                    <p>This information below will visible to all</p>
                                    <h3>ADDRESS</h3>
                                    <div class="form-group">
                                        <textarea
                                            name="address"
                                            v-model="address"
                                            placeholder="Full Address * "
                                            class="form-control"
                                            required=""
                                        ></textarea>
                                        <FormError :title="formErrors.address" />
                                    </div>

                                    <div class="row">
                                        <div class="col-4">
                                            <select
                                                class="reg-sel-1 form-control"
                                                name="state"
                                                v-model="state"
                                                v-on:change="getcity"
                                                required
                                            >
                                                <option disabled value="">State</option>
                                                <option
                                                    v-for="stateItem in states"
                                                    :key="stateItem.state_code"
                                                    :value="stateItem.state_code"
                                                    >{{ stateItem.state_name }}</option
                                                >
                                            </select>
                                            <FormError :title="formErrors.state" />
                                        </div>

                                        <div class="col-4">
                                            <select
                                                name="city"
                                                v-model="city"
                                                class="form-control"
                                                required
                                            >
                                                <option disabled value="">City</option>
                                                <option
                                                    v-for="(city, index) in cities"
                                                    :key="index"
                                                    :value="city.city_code"
                                                    >{{ city.city_name }}</option
                                                >
                                            </select>

                                            <FormError :title="formErrors.city" />
                                        </div>

                                        <!--<input-->
                                        <!--  type="number"-->
                                        <!--  v-model="pincode"-->
                                        <!--  required-->
                                        <!--  name="pincode"-->
                                        <!--  placeholder="Pincode*"-->
                                        <!--  id="pincode"-->
                                        <!--/>-->
                                        <div class="col-4">
                                            <input
                                                type="number"
                                                name="pincode"
                                                placeholder="Pincode*"
                                                id="pincode"
                                                v-model="pincode"
                                                class="form-control"
                                                required
                                            />

                                            <FormError :title="formErrors.pincode" />
                                        </div>
                                        <!-- <select name="pincode" v-model="pincode" id="cars">
                              <option value="">&#xf041; Pincode * </option>
                              <option value="volvo">Volvo</option>
                              <option value="saab">Saab</option>
                           </select> -->
                                    </div>
                                    <button
                                        type="submit"
                                        @click="register"
                                        class="primary-button mt-4 my-2"
                                    >
                                        Post Surplus
                                    </button>
                                    <!-- <button>UPDATE NOW</button> -->
                                </form>
                            </div>
                        </div>
                        <LeftBar></LeftBar>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-center container my-5 p-5" style="background: hsl(120, 100%, 90%)">
                <h5>
                    Surplus Posted successfully.
                </h5>
                <button
                    class="d-inline primary-button"
                    @click="$router.push({ name: 'single-product', params: { id: surplusId } })"
                >
                    View Surplus
                </button>
            </div>
        </div>
    </div>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
<script>
import Vue from "vue"
// import "vue-select/dist/vue-select.css";
import "vue-select/src/scss/vue-select.scss"
import vSelect from "vue-select"
import ImageUploader from "./utils/ImageUploader.vue"
import FormError from "./utils/FormError.vue"

//   import VueSimpleRangeSlider from 'vue-simple-range-slider';
//    import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css';

import axios from "axios"
import { HTTP } from "../_helper/http-constants"
import LeftBar from "../components/leftbar.vue"
import scrollTop from "./utils/scrollTop"
import { mapState, mapActions } from "vuex"
import request from "../apis/request"
import Loader from "./Loader"

export default {
    name: "surplus",
    delimiters: ["${", "}"], // Avoid Twig conflicts
    components: {
        //  VueSimpleRangeSlider,
        vSelect,
        LeftBar,
        ImageUploader,
        Loader,
        FormError,
    },

    data() {
        return {
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            //   imgsrc: "http://localhost/backend/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,
            isLoading: false,
            // number: 0,
            filelist: [],

            ress: {},

            cities: null,
            states: null,
            categores: null,
            selectcategores: [],
            subcategores: [],
            subbcategory: null,
            startValidation: false,
            description: "",
            tag: "",
            title: "",
            state: "",
            city: "",
            category: "",
            subcategory: "",
            price: "",
            offer: "",
            mobile: "",
            address: "",
            image: "",
            multi_image: "",
            video: "",
            video_code: "",
            pincode: "",
            searchcat: "",
            quenty: "",
            surplusPosted: false,
            surplusId: null,

            formErrors: {},

            action: "/surplus",
            postingLimitReached: false,
        }
    },

    computed: {
        ...mapState("auth", {
            user: "user",
            authenticated: "authenticated",
        }),
    },

    watch: {
        offer(value, oldValue) {
            console.log(value, this.price, oldValue)
            if (Number(value) > Number(this.price)) {
                this.offer = oldValue
            }
        },
        title(e) {
            this.updateErrors()
        },
        description(e) {
            this.updateErrors()
        },
        category(e) {
            this.updateErrors()
        },
        quenty(e) {
            this.updateErrors()
        },
        mobile(e) {
            this.updateErrors()
        },
        address(e) {
            this.updateErrors()
        },
        state(e) {
            this.updateErrors()
        },
        city(e) {
            this.updateErrors()
        },
        pincode(e) {
            this.updateErrors()
        },
    },

    methods: {
        ...mapActions("auth", {
            fetchUser: "fetchUser",
            logoutUser: "logout",
        }),
        async checkPostingLimit() {
            try {
                await request.get("/surplus/check-allowed")
            } catch (err) {
                this.postingLimitReached = true
                const message = err.response && err.response.data && err.response.data.message
                if (message && err.response.data.operational) {
                    this.$toasted.error(message || "You are not allowed to post surplus.")
                } else {
                    this.$toasted.error("Something went wrong")
                }
            }
        },
        updateErrors() {
            if (!this.startValidation) return
            this.formErrors = {}
            console.log("form errors before updating", this.formErrors)
            if (!this.title) {
                this.formErrors.title = "Title is required"
            }
            if (!this.description) {
                this.formErrors.description = "Description is required"
            }

            if (!this.state) {
                this.formErrors.state = "State is required"
            }
            if (!this.city) {
                this.formErrors.city = "City is required"
            }
            if (!this.category) {
                this.formErrors.category = "Category is required"
            }

            if (!this.mobile) {
                this.formErrors.mobile = "Mobile is required"
            }
            if (!this.quenty) {
                this.formErrors.quantity = "Quantity is required"
            }
            if (!this.address) {
                this.formErrors.address = "Address is required"
            }

            if (!this.pincode) {
                this.formErrors.pincode = "Pincode is required"
            }

            this.validatemobile()
            this.validatepincode()

            console.log("form errors", this.formErrors)
        },
        register: function(e) {
            e.preventDefault()
            if (this.isLoading) return

            this.startValidation = true
            this.updateErrors()

            this.mobile = $("#number").val()
            this.pincode = $("#pincode").val()

            if (
                !(
                    this.title &&
                    this.description &&
                    this.state &&
                    this.city &&
                    this.address &&
                    this.category &&
                    this.quenty &&
                    this.validatepincode() &&
                    this.validatemobile()
                )
            )
                return this.$toasted.error("Please correct the errors before submitting the form.")

            this.ress = {}

            const form = new FormData()

            form.append("title", this.title)
            form.append("description", this.description)
            form.append("tag", this.tag)
            form.append("category", this.category)
            form.append("subcategory", this.subcategory)
            form.append("state", this.state)
            form.append("city", this.city)
            form.append("price", this.price)
            form.append("offer", this.offer)
            form.append("number", this.mobile)
            form.append("quenty", this.quenty)
            form.append("address", this.address)
            form.append("image", this.image)

            console.log("FILE LIST BEFORE ADDING TO MULTI IMAGE", this.filelist)
            for (let i = 0; i < this.filelist.length; i++) {
                form.append("multi_image[]", this.filelist[i])
            }
            //  form.append('video',this.video);
            form.append("video_code", this.video_code)
            form.append("pincode", this.pincode)

            this.isLoading = true
            request
                .post(this.action, form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    //   window.localStorage.setItem("isLogged", true);
                    //   window.localStorage.setItem("user", JSON.stringify(res.data));
                    //console.log("localstorage", JSON.parse(window.localStorage.getItem('user')));
                    //   alert("Surplus add successfully!");
                    this.isLoading = false
                    this.$toasted.success("Surplus added successfully", {
                        keepOnHover: true,
                        iconPack: "fontawesome",
                        icon: "check",
                        theme: "toasted-primary",
                        // timerProgressBar: true,
                        duration: 3000,
                    })
                    this.surplusId = res.data.result.id
                    this.surplusPosted = true
                    scrollTop()

                    // this.$router.push({ name: "landing" })
                    //  console.log("ok")
                })
                .catch((res) => {
                    //alert('err');
                    this.isLoading = false
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    const message = res.response && res.response.data && res.response.data.message
                    if (message && err.response.data.operational) {
                        this.$toasted.error(message || "You are not allowed to post surplus.")
                    } else {
                        this.$toasted.error("Something went wrong")
                    }
                    return res
                })
        },

        validatemobile: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile.length; i++) {
                if (!(this.mobile[i] >= "0" && this.mobile[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false) msg = "Only Digits allowed without spaces"
            else if (digitCount != 10) msg = "Mobile no. must be 10 digits"
            this.formErrors.mobile = msg
            return res && digitCount == 10
        },

        validatepincode: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.pincode.length; i++) {
                if (!(this.pincode[i] >= "0" && this.pincode[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false) msg = "Oops!!! Digits only"
            else if (digitCount != 6) msg = "Pincode must be 6 digits"
            this.formErrors.pincode = msg
            return res && digitCount == 6
        },

        getcity() {
            axios.get("/api/city/" + this.state).then((response) => (this.cities = response.data))
        },

        getsubcategory() {
            axios
                .get("/api/sub-categories/" + this.category)
                .then((response) => (this.subcategores = response.data))
        },
    },
    mounted() {
        scrollTop()
        this.checkPostingLimit()
    },
    created() {
        axios
            .get("/api/state")

            .then((response) => (this.states = response.data))

        axios
            .get("/api/categories")

            .then((response) => {
                this.selectcategores = []
                response.data.forEach((searchcat) => {
                    this.selectcategores.push({
                        label: searchcat.name,
                        id: searchcat.id,
                    })
                })
            })

        axios
            .get("/api/categories")

            .then((response) => (this.categores = response.data))

        axios
            .get("/api/category")

            .then((response) => (this.subbcategory = response.data))
    },

    beforeRouteEnter(to, form, next) {
        var _vm = this
        request
            .get("/user")
            .then((response) => {
                const user_id = response.data
                // console.log("user_id.role_id", user_id.role_id);

                if (user_id.role_id == 2) {
                    next()
                } else {
                    throw new Error("Must be a buyer/seller")
                }
                // return next({ name: "login" });
            })
            .catch((err) => {
                console.log("catch section is running", err)
                return next({ name: "login" })
            })
    },
    beforeDestroy() {
        this.isLoading = true
        console.log("single product page unmounting")
    },
}
</script>

<style>
select {
    font-family: "FontAwesome", "sans-serif";
}
input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: #ea6a69;
    border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ea6a69;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ea6a69;
}
.range-wrap {
    /*width: 500px;*/
    position: relative;
}
.range-value {
    position: absolute;
    top: -50%;
}
.range-value span {
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #ea6a69;
    color: #fff;
    font-size: 12px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
}
.range-value span:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #ea6a69;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
}
[v-cloak] {
    display: none;
}
</style>
